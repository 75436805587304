import { environment, tcapp } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient , HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders} from  '@angular/common/http';
import { BehaviorSubject, throwError, from  } from  'rxjs';
import { takeUntil, map, catchError, retry } from 'rxjs/operators';

import { Observable, Subject } from  'rxjs';
import { GlobalService } from './global.service';

//import  'rxjs/add/operator/map';


class list_asset_types_input {
	qoptions : Map<string, qoptions>;
}

class qoptions {
	where : Map<string, string>;
}


export class asset_type_info {
    id		:string;
    entity_id	:string;
	controlled_by	:string;  // which asset controls this
    name	:string;
	kind    : string;  //  "collection"
    description	: string;
	correlation		: Map<string, string>;
	kv		: Map<string, string>;
	collection_type_list		: Map<string, string>;
    addressable	:boolean;
    root_asset_flag	: boolean;

	constructor(values: Object = {}) 
	{  
		Object.assign(this, values);  
	}

	public get_asset_type() {
		return (this.name);
	}

	public get_asset_kind() {
		return (this.kind);
	}
}

const resturl = "/tc/v1/assets/";

@Injectable(
)

export class AssetTypeService {

	hostUrl:string = tcapp.server_url;
    readystatus$: BehaviorSubject<boolean> = new BehaviorSubject(null);

	public ready():Observable<boolean> {
		return this.readystatus$.asObservable();
	}

	constructor(private  httpClient : HttpClient,
		private platform: Platform,
		private global: GlobalService,
		private storage: Storage) {
		var getpromises = [];
		this.readystatus$.next(false);

		this.global.ready()
	      .pipe(takeUntil(this.readystatus$),)
		  .subscribe(ready => {
			if (!ready) {
				return
			}
			this.readystatus$.next(true);
			console.log("Asset Type Service : Settings loaded");
		});
	}

	public  get_asset_types(id?:string): Observable<asset_type_info[]> {

		let url = this.hostUrl+ resturl+"list-asset-types";

		let hdr = new HttpHeaders();

		console.log("Contacting", url);
		hdr = hdr.append('Accept', 'application/json');
		return this.httpClient
		.get<asset_type_info[]>(url, {headers:hdr})
		.pipe(
			retry(3),
			(map(response => {
				if (response && response.length > 0) {
					console.log(response); 
					return response;
				} else {
					return response; 
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}
}
