import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './login/login.component';
// import { DashboardComponent } from './dashboard/dashboard.component';
import { ManagedControllerComponent } from './managed-controller/managed-controller.component';
//import { EntityPageModule } from './home/entity/entity.module';
//import { EntityPage } from './home/entity/entity.page';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' }, // Redirect to login by default
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then(m => m.LoginModule)
  },
  // {
  //   path: 'dashboard',
  //   component: DashboardComponent,
  //   children: [
  //     {
  //       path: '',
  //       pathMatch: 'full',
  //       redirectTo: 'myhome',
  //     },
  //     {
  //       path: 'myhome',
  //       loadChildren: () => import('./myhome/myhome.module').then((m) => m.MyhomeModule),
  //     },
  //     {
  //       path: 'entities',
  //       loadChildren: () => import('./entities/entities.module').then((m) => m.EntitiesModule),
  //     },
  //     // {
  //     //   path: 'library',
  //     //   loadChildren: () => import('./library/library-page.module').then((m) => m.LibraryPageModule),
  //     // },
  //     {
  //       path: 'profile',
  //       loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
  //     },
  //   ],
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'dashboard/myhome',
    loadChildren: () => import('./myhome/myhome.module').then((m) => m.MyhomeModule),
  },
  {
    path: 'dashboard/entities',
    loadChildren: () => import('./entities/entities.module').then((m) => m.EntitiesModule),
  },
  // {
  //   path: 'library',
  //   loadChildren: () => import('./library/library-page.module').then((m) => m.LibraryPageModule),
  // },
  {
    path: 'dashboard/profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'change-pass',
    loadChildren: () => import('./changepass/changepass.module').then((m) => m.ChangepassModule),
    canActivate: [AuthGuard],
  },
  {
    path: "managed-controller",
    loadChildren: () => import('./managed-controller/managed-controller.module').then((m) => m.ManagedControllerModule),
    canActivate: [AuthGuard],
  },
  {
    path: "controller-view",
    loadChildren: () => import('./controller-view/controller-view.module').then((m) => m.ControllerViewModule),
    canActivate: [AuthGuard],
  },
  {
    path: "config-profile",
    loadChildren: () => import('./config-profile/config-profile.module').then((m) => m.ConfigProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: "edit-irrigation",
    loadChildren: () => import('./edit-irrigation-profile/edit-irrigation-profile.module').then((m) => m.EditIrrigationProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: "edit-nutrient",
    loadChildren: () => import('./edit-nutrient-profile/edit-nutrient-profile.module').then((m) => m.EditNutrientProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: "controller-timing",
    loadChildren: () => import('./controller-timing/controller-timing.module').then((m) => m.ControllerTimingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'controller-history',
    loadChildren: () => import('./controller-history/controller-history.module').then((m) => m.ControllerHistoryModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'controller-history-more',
    loadChildren: () => import('./controller-history-graph-more/controller-history-graph-more.module').then((m) => m.ControllerHistoryGraphMoreModule),
    canActivate: [AuthGuard],
  },
  // { path: '**', redirectTo: 'login' } // Redirect any unknown routes to login
  // {
  //   path: '',
  //   component: LoginComponent,
  //   children: [

  //     // {
  //     //   path: 'home',
  //     //   loadChildren: () => import('./home/home-page.module').then((m) => m.HomePageModule),
  //     // },
  //     // {
  //     //   path: 'radio',
  //     //   loadChildren: () => import('./radio/radio-page.module').then((m) => m.RadioPageModule),
  //     // },
  //     // {
  //     //   path: 'library',
  //     //   loadChildren: () => import('./library/library-page.module').then((m) => m.LibraryPageModule),
  //     // },
  //     // {
  //     //   path: 'search',
  //     //   loadChildren: () => import('./search/search-page.module').then((m) => m.SearchPageModule),
  //     // },
  //   ],
  // },
  /*
  { path: 'operations', loadChildren: './home/operations/operations.module#OperationsPageModule' }
  { path: 'schema', loadChildren: './home/config/schema/schema.module#SchemaPageModule' }
  { path: 'asset', loadChildren: './home/asset/asset.module#AssetPageModule' },
  { path: 'asset-fill', loadChildren: './home/asset/asset-fill/asset-fill.module#AssetFillPageModule' }
  { path: 'home/entity', 
  loadChildren: './home/entity/entity.module#EntityPageModule' 
      },
  { path: 'home/entity/:id', loadChildren: './home/entity/entity.module#EntityPageModule' },
   */
  //{ path: 'entity-fill', loadChildren: './home/entity/entity-fill/entity-fill.module#EntityFillPageModule' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
