import { AlertController, NavController } from '@ionic/angular';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
 
import { throwError, Observable } from 'rxjs';
//import { _throw } from 'rxjs/observable/throw';
import { take, flatMap,  map, catchError, mergeMap } from 'rxjs/operators';
import { TokenService } from '../services/token.service';
import { UserService } from '../services/user.service';
import { LoginService } from '../services/login.service';
import { BehaviorSubject } from "rxjs/BehaviorSubject";
 
@Injectable()
export class InterceptorService implements HttpInterceptor {
	private refreshTokenInProgress = false;
	private access_token:string = null;
    // Refresh Token Subject tracks the current token, or is null if no token is currently // available (e.g. refresh pending).

    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );
 
  constructor(private storage: Storage, private alertCtrl: AlertController, private tokensvc:TokenService, private usersvc:UserService, private loginsvc:LoginService,
	private navCtrl: NavController
  ) { }
 
    // Intercepts all HTTP requests!
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
		let bearer_token = request.headers.get("Authorization")
	  /*
		if (bearer_token) {
			return next.handle(request).catch(error => {
				return this.handle_error(request, next, error);
				//return Observable.throw(error);
			});
		}
	   */

		if ( request.url.includes("login") ||
			request.url.includes("refreshtoken") ) {
			return next.handle(request).catch(error => {
				return Observable.throw(error);
			});
		}

		const tokenObservable =  this.tokensvc.getTokenAsObservable()
			.pipe(map(token => (
					request = request.clone({
					  setHeaders: {
						Authorization: 'Bearer ' + token,
						'Accept': `application/json`,
						'Content-Type': `application/json`,
						'rejectUnauthorized': 'false',
						'requestCert': 'true',         
						'agent': 'false',         
						'strictSSL': 'false',
					  }
					})
			)));

		// Avoid caching
		this.access_token = null;
		if (! this.access_token) {
			this.tokensvc.getToken().then(token => {
				if (token) 
					this.access_token = token;
				else 
					return Observable.throw("Access token not available");
			});
			
			if (this.refreshTokenInProgress) {
			// If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
			// – which means the new token is ready and we can retry the request again
				return this.refreshTokenSubject
				.filter(result => result !== null)
				.take(1)
				.switchMap(() => next.handle(this.addToken(request)));
			}

			return tokenObservable.pipe(flatMap(request => {
				let token = request.headers.get("Authorization")
				var splitted = token.split(" ", 2);
				if (splitted[1] == "null") {
					return throwError("No access token");
				}
				return next.handle(request).catch(error => {
					return this.handle_error(request, next, error);
				});
			}));
		} 
   }

	//private handle_error(request:any, next:any, error:any) : Observable<HttpEvent<any>> {
   private handle_error(request:any, next:any, error:any) : Observable<any> {

   // We don't want to refresh token for some requests like login or refresh token itself
		  // So we verify url and we throw an error if it's the case
		if (
			request.url.includes("gettoken") ||
			request.url.includes("refreshtoken") ||
			request.url.includes("login")
		) {
			// We do another check to see if refresh token failed
			// In this case we want to logout user and to redirect it to login page
			if (request.url.includes("refreshtoken")) {
				this.usersvc.delete_user_auth();
				//	this.usersvc.setloginstatus(false);
			}
			return Observable.throw(error);
		}

		// If error status is different than 401 we want to skip refresh token
		// So we check that and throw the error if it's the case
		if (error.status !== 401) {
			return Observable.throw(error);
		}

		this.access_token = null;
		if (this.refreshTokenInProgress) {
			// If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
			// – which means the new token is ready and we can retry the request again
			return this.refreshTokenSubject
				.filter(result => result !== null)
				.take(1)
				.switchMap(() => next.handle(this.addToken(request)));
		} else {
			this.refreshTokenInProgress = true;

			// Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
			this.refreshTokenSubject.next(null);

			// Call auth.refreshAccessToken(this is an Observable that will be returned)
			return this.loginsvc.refresh_token().pipe(
				flatMap(response => {
				//When the call to refreshToken completes we reset the refreshTokenInProgress to false
				// for the next time the token needs to be refreshed
				  if (response) {
					this.access_token = response.access_token;
					this.refreshTokenInProgress = false;
					this.usersvc.store_user_auth(response);
					return (next.handle(this.addToken(request)));
				  } else {
					return Observable.throw(error);
				  }
			}),
			catchError((err: any) => {
			  if (! this.refreshTokenInProgress) {
				return Observable.throw(err);
			  } else {
				console.log("Refresh Token Error:", err);
				this.refreshTokenInProgress = false;
				this.navCtrl.navigateRoot('login');
				this.usersvc.delete_user_auth();
				this.usersvc.setloginstatus(false);
				return Observable.throw(err);
			  }
			})
		  );
		  //return Observable.throw(error);
		}
	}
 
    // Adds the token to your headers if it exists
    private addToken(request: HttpRequest<any>) {
        if (this.access_token) {
            let clone: HttpRequest<any>;
            clone = request.clone({
                setHeaders: {
                    'Accept': `application/json`,
                    'Content-Type': `application/json`,
					'rejectUnauthorized': 'false',
					'requestCert': 'true',         
					'agent': 'false',         
					'strictSSL': 'false',
                    'Authorization': `Bearer ${this.access_token}`
                }
            });
            return clone;
        }
        return request;
    }
}
