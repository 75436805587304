import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular'; 
import { ReplaySubject } from 'rxjs';
import { Menu_item } from '../menu/menu.component';

@Injectable(
)

export class MenuService {

  public title: ReplaySubject<Menu_item[]> = new ReplaySubject<Menu_item[]>(1);
  public details: ReplaySubject<Menu_item[]> = new ReplaySubject<Menu_item[]>(1);

	constructor(public menu: MenuController) {
		this.menu.enable(true, 'mainmenu');
		this.menu.open('mainmenu');
	}

  public toggle() {
    this.menu.toggle('main-menu');
  }
}
