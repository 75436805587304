import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { from } from 'rxjs';

const TOKEN_KEY = "X-Auth-Token";
const LOGINAS_TOKEN_KEY = "LoginAs-Token";

@Injectable(
)

export class TokenService {
  constructor(private global: GlobalService,
	private storage: Storage) {

	  this.global.ready()
	  .subscribe(ready => {
		  if (!ready) {
			  return
		  }
	  });
    }

	getToken(){
		return this.storage.ready().then(() => {
			return (this.storage.get(LOGINAS_TOKEN_KEY)
			.then (resp => { 
				if (resp) {
					return resp;
				} else {
					return this.storage.get(TOKEN_KEY)
					.then(
					  data => {
						return data;
					  },
					  error => console.error(error)
					);
				}
			},
			err => {
				console.error(err);
			}));
		});
	}

	getTokenAsObservable() {
		return from(this.getToken());
	}
}
