import { environment, tcapp } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { HttpClient , HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders} from  '@angular/common/http';
import { BehaviorSubject, throwError, from  } from  'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { Observable, Subject } from  'rxjs';
import { GlobalService } from './global.service';
import { Storage } from '@ionic/storage';

const TOKEN_KEY = "X-Auth-Token";
const REFRESH_TOKEN_KEY = "X-Refresh-Token";
const LOGINAS_TOKEN_KEY = "LoginAs-Token";
const USER_NAME = "USER_NAME";
const USER = "USER";
const HOST_URL = "HOST_URL";
const ENTITY_ID = "ENTITY_ID";

export class user_auth {
    id            :string;
    access_token  :string;
    refresh_token :string;
    expires_in    :number; 

	constructor(values: Object = {}) 
	{  
		Object.assign(this, values);  
	}
}

export class user_cred {
    id         :string;
    password   :string;
	kv		   : Map<string, string>;

	constructor(values: Object = {}) 
	{  
		Object.assign(this, values);  
	}
}

export class user_info {
    id         :string;
    email      :string;
    saas_entity_name  :string;
    entity_id  :string;
    role       :string;
    first_name :string;
    last_name  :string;
	kv		   : Map<string, string>;
    tags       :string;

	constructor(values: Object = {}) 
	{  
		Object.assign(this, values);  
	}
	
	public get_name() {
		return (this.first_name + ' ' + this.last_name);
	}
}

class delete_user_cred_input {
	id : string;
}

class delete_user_input {
	qoptions : Map<string, qoptions>;
}

class find_user_input {
	qoptions : Map<string, qoptions>;
}

class qoptions {
	where : Map<string, string>;
}

const resturl = "/tc/v1/users/";

@Injectable(
)

export class UserService {

    readystatus$: BehaviorSubject<boolean> = new BehaviorSubject(null);

	public ready():Observable<boolean> {
		return this.readystatus$.asObservable();
	}
	hostUrl:string = environment.server_url;
    authState$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    initState$: BehaviorSubject<boolean> = new BehaviorSubject(null);
	public loginAs_token:string;

	constructor(private  httpClient : HttpClient,
		private storage: Storage,
		private platform: Platform,
		private global: GlobalService) {
		var getpromises = [];
		this.readystatus$.next(true);
		this.initState$.next(false);

		this.global.ready()
		.subscribe(ready => {
			if (!ready) {
				return
			}
			console.log("User Service : Settings loaded");
			if (this.global.token) {
				this.authState$.next(true);
			} else {
				this.authState$.next(false);
			}
			this.readystatus$.next(true);
		});
	}


	private auth:user_auth;
	private user:user_info;

	public  seturl (s){
		this.global.seturl(s);
	}

	public  checkloginstatusObserver (): Observable<boolean> { 
		return this.authState$.asObservable();
    }

	public  checkinitstatusObserver (): Observable<boolean> { 
		return this.initState$.asObservable();
    }

	public  checkloginstatus() { 
		return this.authState$.value;
     }
	public  checkinitstatus() { 
		return this.initState$.value;
     }
		
	public  setloginstatus (s){
		this.authState$.next(s);
	    console.log("Logged in status set to", s)
	}

	public create_user_cred(user_c:user_cred, modify_flag?:boolean): Observable<any> {

		var input_json ;
		var url:string;

		if (modify_flag) {
			url = this.global.hostUrl+ resturl+"modify-user-credentials";
		} else {
			url = this.global.hostUrl+ resturl+"create-user-credentials";
		}

		let hdr = new HttpHeaders();

		// Set entity_id of the user
		//asset.entity_id = this.user_entity;
		input_json = JSON.stringify(user_c);

		console.log("Contacting", url, input_json);
		hdr = hdr.append('Accept', 'application/json');
		hdr = hdr.append('observe', 'response');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		//hdr = hdr.append("Authorization", "Bearer " + this.token) ;
		return this.httpClient
		.post(url, input_json, {headers:hdr, observe:'response'})
		.pipe(
		  //retry(3),
			(map(response => {
				if (response) {
					console.log(response); 
					return response;
				} else {
					return response
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public delete_user_object(user:user_info, flag?:string): Observable<any> {

		var input_json ;
		var url:string;
		var dp:any;

		if (flag == "user-cred") {
			url = this.global.hostUrl+ resturl+"delete-user-credentials";
			dp = new(delete_user_cred_input);
			dp.id = user.id;
		} else {
			url = this.global.hostUrl+ resturl+"delete-user";
			dp = new(delete_user_input);
			dp.qoptions = new Map<string, qoptions>();
			dp.qoptions['where'] = {email:user.email, saas_entity_name:user.saas_entity_name};
		}

		let hdr = new HttpHeaders();

		// Set entity_id of the user
		input_json = JSON.stringify(dp);

		dp = null;
		console.log("Contacting", url, input_json);
		hdr = hdr.append('Accept', 'application/json');
		//hdr = hdr.append('observe', 'response');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.post(url, input_json, {headers:hdr})
		.pipe(
			retry(3),
			(map(response => {
				if (response) {
					console.log(response); 
					return response;
				} else {
					return response
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public create_user(user:user_info, modify_flag?:boolean): Observable<any> {

		var input_json ;
		let url = this.global.hostUrl+ resturl+"create-user";

		let hdr = new HttpHeaders();

		// Set entity_id of the user
		//asset.entity_id = this.user_entity;
		input_json = JSON.stringify(user);

		console.log("Contacting", url, input_json);
		hdr = hdr.append('Accept', 'application/json');
		hdr = hdr.append('observe', 'response');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		//hdr = hdr.append("Authorization", "Bearer " + this.token) ;
		return this.httpClient
		.post(url, input_json, {headers:hdr, observe:'response'})
		.pipe(
		  //	retry(3),
			(map(response => {
				if (response) {
					console.log(response); 
					return response;
				} else {
					return response
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public  find_user(email:string): Observable<user_info> {
		var input_json ;
		var url = this.global.hostUrl+ resturl+"find-user";
		var hdr = new HttpHeaders();

		console.log("Contacting", url);
		let dp = new(find_user_input);
		dp.qoptions = new Map<string, qoptions>();
		dp.qoptions['where'] = {email:email, saas_entity_name:tcapp.saas};

		input_json = JSON.stringify(dp);
		dp = null;
		hdr = hdr.append('Accept', 'application/json');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
	  .post<user_info>(url, input_json, {headers:hdr})
		.pipe(
			retry(3),
			(map(response => {
				if (response) {
					console.log(response); 
					return new user_info(response);
				} else {
					return response
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}


	public  get_self(): Observable<user_info> {
		var url = this.hostUrl+ resturl+"get-user";
		var hdr = new HttpHeaders();
		console.log("Contacting", url);
		hdr = hdr.append('Accept', 'application/json');
		//hdr = hdr.append("Authorization", "Bearer " + this.auth.access_token) ;
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.get<user_info>(url, {headers:hdr})
		.pipe(
			retry(3),
			(map(response => {
				if (response) {
					console.log(response); 
					return new user_info(response);
				} else {
					return response
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public store_user_auth(auth):any{
		this.auth = new user_auth(auth);
	    if (this.global.loginAs) {
		  this.storage.set(LOGINAS_TOKEN_KEY, this.auth.access_token).then( res => {
			this.global.loginAs_token = res;
		});
		}
		return this.storage.set(TOKEN_KEY, this.auth.access_token).then( res => {
			// TBD User FLATMAP here
			this.global.token = res;
			this.storage.set(REFRESH_TOKEN_KEY, this.auth.refresh_token).then(res => {
			this.global.refresh_token = res;
			});
			console.log("Logged in");
			this.get_self().subscribe( (response) => {
					var obj = new user_info(response);
					// get_self returns an array
					this.user = obj[0];
					this.global.user = obj[0];
					this.global.user_entity = obj[0].entity_id;
					this.storage.set(USER, this.user).then(res => {
						//this.authState$.next(true);
						console.log("Stored user name and entity ", res);
						this.initState$.next(true);
						return response;
					});
			});
		});
	}


	public delete_user_auth(loginAS?:boolean) {
		this.global.delete_user_auth(loginAS);
		this.initState$.next(false);
		this.authState$.next(false);
	}
}
