import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiteModel } from 'src/app/common/core/api-interfaces/site-interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SiteListService {

  constructor(private http: HttpClient) { }

  getEntityList() {
    const url = environment.server_url + "/tc/v1/entities/get-entity";
    return this.http.get<SiteModel[]>(url);
  }

  // Get Sites ----------------------------------
  getSiteList(body: any) {
    const url = environment.server_url + "/tc/v1/entities/list-child-entities";
    return this.http.post<SiteModel[]>(url, body);
  }
}
