import { environment, tcapp } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient , HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders} from  '@angular/common/http';
import { BehaviorSubject, throwError, from  } from  'rxjs';
import { mergeMap, take, map, catchError, retry, retryWhen } from 'rxjs/operators';
import { Observable, Subject } from  'rxjs';
import { GlobalService } from './global.service';

export class stats_query_input {
    data_type   :string ;
    resource    :string;
    entity_name :string;
    asset_name :string;
    aggregation_type :string;
    start_time  :string;
    end_time    :string;
    severity    :string[];
	foptions    :Map<string, any>;
    pcontext    :string;
}


export class stats_query_response {
    data_type   :string;
    start_time  :string;
    end_time    :string;
    entity_name :string;
    count :      number;
    pcontext    :string;
	records : drecord[];
}



class drecord {
	resource_id : string;
    entity_name : string;
	ts : Date;
	aggrevation_interval : number;
	asset_name : string;
	kv    :Map<string, any>;
	aggregation_table : Map<string, stats>;

	constructor(values: Object = {}) 
	{  
		Object.assign(this, values);  
	}
}

class stats {
	avg: number;
	count : number;
	max : number;
	min : number;
	rate: number;
	stat_type : string;
	sum : number
}


const resturl = "/tc/v1/data/";

@Injectable(
)

export class StatsqueryService {

    readystatus$: BehaviorSubject<boolean> = new BehaviorSubject(null);

	public ready():Observable<boolean> {
		return this.readystatus$.asObservable();
	}

	constructor(private  httpClient : HttpClient,
		private platform: Platform,
		private global: GlobalService,
		private storage: Storage) {

		this.readystatus$.next(false);
		this.ngOnInit();
	}

	public ngOnInit() {
		var getpromises = [];

		this.global.ready()
		.subscribe(ready => {
			if (!ready) {
				return
			}

			this.readystatus$.next(true);
			console.log("Statsquery Service : Settings loaded");
		});
	}

	public refresh() {
		this.global.ready()
		.subscribe(ready => {
			if (!ready) {
				return
			}
			this.readystatus$.next(true);
			console.log("Statsquery Service : Settings refreshed");
		});
	}

	private mapToJson(map) {
		return JSON.stringify([...map]);
	}

	private  get_data_common(url:string, qi?:stats_query_input): Observable<stats_query_response | Error> {
		let hdr = new HttpHeaders();
		/*
		input_rec.qoptions = new Map<string, foptions>();
		input_rec.qoptions['where'] = {apply_to : ' key ' + asset_id};
		input_rec.qoptions['contains_flag'] = true;
		 */
		var input_json = JSON.stringify(qi);

		console.log("Contacting", url);
		hdr = hdr.append('Accept', 'application/json');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.post<stats_query_response>(url,input_json, {headers:hdr})
		.pipe(
			(map(response => {
				if (response) {
					console.log(response); 
					return response;
				} else {
					return response; 
					//		return Observable.of([]);
				}
			})),
			retryWhen(errors => {
				return errors.pipe(
				  mergeMap(error => {
					console.log("Error : ", error);
					return (error.status === 500 || error.status === 404) ? Observable.throw(error) : Observable.of(error);
				  }),
				  take(2)
				)
			  }),
			/*
			retryWhen(errors => {
				return errors.flatMap((error: any) => {
					 if(error.status  === 503) {
					   return Observable.of(error.status).delay(1000)
					 }
					 let err = this.global.chandleError(error);
					 return Observable.throw({error: err});
					 return Observable.of(err);
				})
				.pipe(
					take(3)
					//concat(Observable.throw(errors))
				);
			}),
			 */
			/*
			retryWhen(error => {
				   return error.flatMap((error: any) => {
					 if(error.status  === 503) {
					   return Observable.of(error.status).delay(1000)
					 }
					  //return Observable.of(error)
					  //return Observable.throw({error: 'No retry'});
				   })
                  .take(2)
            }),
			 */
			catchError(this.global.chandleError)
			//retry(1),
		);

	}

	public  get_stats_by_asset(qi?:stats_query_input): Observable<stats_query_response | Error> {

		let url = this.global.hostUrl+ resturl+"query-stats-by-asset";
		return this.get_data_common(url, qi);
	}

	public  get_stats_by_entity(qi?:stats_query_input): Observable<stats_query_response | Error> {

		let url = this.global.hostUrl+ resturl+"query-stats";
		return this.get_data_common(url, qi);
	}
}
