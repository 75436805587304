import { NgModule, ModuleWithProviders} from '@angular/core';
import { LoginService } from './login.service';
import { UserService } from './user.service';
import { EntityService } from './entity.service';
import { AssetService } from './asset.service';
import { AssetTypeService } from './asset_type.service';
import { ProfileService } from './profile.service';
import { SchemaService } from './schema.service';
import { TokenService } from './token.service';
import { ProvisionService } from './provision.service';
import { GlobalService } from './global.service';
import { MenuService } from './menu.service';
import { DataqueryService } from './dataquery.service';
import { StatsqueryService } from './statsquery.service';
import { PhoneValidator } from '../common/phone-validator';

@NgModule({
})
export class SharedServicesModule {
	 static forRoot():ModuleWithProviders {
        return {
            ngModule: SharedServicesModule,
            providers: [MenuService, LoginService, UserService, EntityService, AssetService, TokenService, AssetTypeService, ProfileService, SchemaService, ProvisionService, DataqueryService, StatsqueryService, PhoneValidator, GlobalService]
        };
	 }
}
