import { environment, tcapp } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { HttpClient , HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders} from  '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from  'rxjs';
import { BehaviorSubject, throwError, from  } from  'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { GlobalService } from './global.service';

import  'rxjs/add/operator/catch';

import  'rxjs/add/operator/map';


export class push_config_input {
	site_id : string;
	access_token : string;
	asset_id : string;
	operation : string;
	debug : boolean;
	encode:string;
	cnfg_check:boolean;
	transport : string = "sms";
}

export class controller_response {
    cid     :string   ;
    name    :string  ;
    desc    :string ;
    mobile  :string;
    cmds    :string[];
    replies :string[];
    status  :number    ;
}

export class config_response {
    statustext :string;
    response :controller_response[] ;
}

export class asset_operation_input {
	site_id : string;
	asset_id : string;
	access_token : string;
	operation : string;
	debug : boolean;
	encode:string;
}

const provurl = "/tcprov/";

@Injectable(
)

export class ProvisionService {
	constructor(private  httpClient : HttpClient ,
		private storage: Storage,
		private global: GlobalService,
		private platform: Platform) {

		this.global.ready()
		.subscribe(ready => {
			if (!ready) {
				return
			}
			console.log("Provision Service : Ready");
		});
	}


	public  push_user_config(l:any, bulk_flag?:boolean): Observable<any> {

		var re = /\:\d+$/;
		if (tcapp.encode != "") {
			l.encode = tcapp.encode;
		} else {
			l.encode = "plaintext";
		}
		let curl = environment.server_url;
		let hurl = curl.replace(re, ":8081");
		let url = hurl + provurl+"v1/push-user-config";
		if (bulk_flag) {
			url = hurl + provurl+"v1/push-bulk-user-config";
		} 
		console.log("Contacting Rest Endpoint", url);
		var hdr = new HttpHeaders();
		hdr = hdr.append('Accept', 'application/json');
		return this.httpClient
		.post(url, JSON.stringify(l), {headers:hdr, observe:'response'})
		.pipe(
			(map(response => {
				if (response ) {
					console.log(response); 
					return response; 
				} else {
					return response; 
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public  push_config(l:any, dummy?:boolean): Observable<any> {

		var re = /\:\d+$/;
		let curl = this.global.hostUrl;
		let hurl = curl.replace(re, ":8081");
		let url = hurl + provurl+"v1/push-config";
		console.log("Contacting Rest Endpoint", url);
		var hdr = new HttpHeaders();
		hdr = hdr.append('Accept', 'application/json');
		if (tcapp.encode != "") {
			l.encode = tcapp.encode;
		} else {
			l.encode = "plaintext";
		}
		return this.httpClient
		.post(url, JSON.stringify(l), {headers:hdr, observe:'response'})
		.pipe(
			(map(response => {
				if (response ) {
					console.log(response); 
					return response; 
				} else {
					return response; 
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public  asset_operation(l): Observable<any> {
		var re = /\:\d+$/;
		let curl = this.global.hostUrl;
		let hurl = curl.replace(re, ":8081");
		let url = hurl + provurl+"v1/asset-operation";
		console.log("Contacting Rest Endpoint", url);
		var hdr = new HttpHeaders();
		hdr = hdr.append('Accept', 'application/json');
		if (tcapp.encode != "") {
			l.encode = tcapp.encode;
		} else {
			l.encode = "plaintext";
		}
		return this.httpClient
		.post(url, JSON.stringify(l), {headers:hdr, observe:'response'})
		.pipe(
			(map(response => {
				if (response ) {
					console.log(response); 
					return response; 
				} else {
					return response; 
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}
}
