import { environment, tcapp } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { HttpClient , HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders} from  '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { map, catchError, retry } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError, from  } from  'rxjs';
import { GlobalService } from './global.service';
import { takeUntil } from  'rxjs/operators';


export class login_response {
    id            :string;
    access_token  :string;
    refresh_token :string;
    expires_in    :number; 
	constructor(values: Object = {}) 
	{  
		Object.assign(this, values);  
	}
}

export class login_info {
	username : string;
	password : string;
	constructor(values: Object = {}) 
	{  
		Object.assign(this, values);  
	}
}

class refresh_token_input {
	refresh_token : string;
}

const resturl = "/tc/";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

	//public hostUrl:string = "https://localhost:8082";
	public hostUrl:string = environment.server_url;
    readystatus$: BehaviorSubject<boolean> = new BehaviorSubject(null);
	public username:string = tcapp.user_name;
	private r_token:string;

	public ready():Observable<boolean> {
		return this.readystatus$.asObservable();
	}

	constructor(private  httpClient : HttpClient ,
		private storage: Storage,
		private global: GlobalService,
		private platform: Platform) {
		var getpromises = [];
		this.readystatus$.next(false);
		this.username = "";
		this.global.ready()
	      .pipe(takeUntil(this.readystatus$),)
		  .subscribe(resp => {
			  this.readystatus$.next(true);
			  console.log("Login Service : Ready");
		});
	}

	public  seturl (s){
		this.global.seturl(s);
	}

	public  login(l): Observable<login_response> {
		var url = this.hostUrl+resturl+"login";
		console.log("Contacting Rest Endpoint", url);
		return this.httpClient
		.post<login_response>(url, JSON.stringify(l))
		.pipe(
			retry(1),
			(map(response => {
				if (response) {
					console.log(response); 
					this.r_token = response.refresh_token;
					return new login_response(response);
				} else {
					return response
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
		//.catch((error)=>{
		//	console.error(error);
		//});
	}

	public  refresh_token(): Observable<login_response> {
		if (! this.global.refresh_token) {
			return throwError("Cannot relogin");
		}
		let r = new (refresh_token_input);
		r.refresh_token = this.global.refresh_token;
		var url = this.hostUrl+resturl+"refreshtoken";
		console.log("Contacting Rest Endpoint", url);
		return this.httpClient
		.post<login_response>(url, JSON.stringify(r))
		.pipe(
			retry(1),
			(map(response => {
				if (response) {
					console.log(response); 
					return new login_response(response);
				} else {
					return response
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
		//.catch((error)=>{
		//	console.error(error);
		//});
	}

	public  loginAs(l): Observable<login_response> {
		var url = this.global.hostUrl+resturl+"gettoken";
		console.log("Contacting Rest Endpoint", url);
		return this.httpClient
		.post<login_response>(url, JSON.stringify(l))
		.pipe(
			retry(1),
			(map(response => {
				if (response) {
					console.log(response); 
					return new login_response(response);
				} else {
					return response
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public  logout(){
	}

	private handleError(error: HttpErrorResponse) {
		var message:string;
		if (error.error instanceof ErrorEvent ) {
		// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
			message=error.statusText + error.error.message;
		} else {
		// The backend returned an unsuccessful response code.
		// The response body may contain clues as to what went wrong,
		   console.error(
		  `Backend returned code ${error.status}, ` +
		  `body was: ${error.error}`);
		   message = error.status + ":"  + error.error ;
		}
		// return an observable with a user-facing error message
		return throwError(error);
	}

}
