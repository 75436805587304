import { environment, tcapp } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient , HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders} from  '@angular/common/http';
import { BehaviorSubject, throwError, from  } from  'rxjs';
import { mergeMap, take, map, catchError, retry, retryWhen } from 'rxjs/operators';
import { Observable, Subject } from  'rxjs';
import { GlobalService } from './global.service';

export class data_query_input {
    data_type   :string ;
    resource    :string;
    entity_name :string;
    start_time  :string;
    end_time    :string;
    severity    :string[];
	foptions    :Map<string, any>;
    pcontext    :string;
}


export class data_query_response {
    data_type   :string;
    start_time  :string;
    end_time    :string;
    entity_name :string;
    count :      number;
    pcontext    :string;
	records : drecord[];
}


class drecord {
	resource : string;
    entity_name : string;
	seq : number;
	day : string;
	ts : Date;
	asset_type : string;
	asset_name : string;
	description : string;
	severity : string;
	details : string;
	app : string

	constructor(values: Object = {}) 
	{  
		Object.assign(this, values);  
	}
}


const resturl = "/tc/v1/data/";

@Injectable(
)

export class DataqueryService {

    readystatus$: BehaviorSubject<boolean> = new BehaviorSubject(null);

	public ready():Observable<boolean> {
		return this.readystatus$.asObservable();
	}

	constructor(private  httpClient : HttpClient,
		private platform: Platform,
		private global: GlobalService,
		private storage: Storage) {

		this.readystatus$.next(false);
		this.ngOnInit();
	}

	public ngOnInit() {
		var getpromises = [];

		this.global.ready()
		.subscribe(ready => {
			if (!ready) {
				return
			}

			this.readystatus$.next(true);
			console.log("Dataquery Service : Settings loaded");
		});
	}

	public refresh() {
		this.global.ready()
		.subscribe(ready => {
			if (!ready) {
				return
			}
			this.readystatus$.next(true);
			console.log("Dataquery Service : Settings refreshed");
		});
	}

	private mapToJson(map) {
		return JSON.stringify([...map]);
	}

	private  get_data_common(url:string, qi?:data_query_input): Observable<data_query_response> {
		let hdr = new HttpHeaders();
		/*
		input_rec.qoptions = new Map<string, foptions>();
		input_rec.qoptions['where'] = {apply_to : ' key ' + asset_id};
		input_rec.qoptions['contains_flag'] = true;
		 */
		var input_json = JSON.stringify(qi);

		console.log("Contacting", url);
		hdr = hdr.append('Accept', 'application/json');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.post<data_query_response>(url,input_json, {headers:hdr})
		.pipe(
			//retry(3),
			(map(response => {
				if (response) {
					console.log(response); 
					return response;
				} else {
					return response; 
					//		return Observable.of([]);
				}
			})),
			retryWhen(errors => {
				return errors.pipe(
				  mergeMap(error => {
					console.log("Error : ", error);
					return (error.status === 500 || error.status === 404) ? Observable.throw(error) : Observable.of(error);
				  }),
				  take(2)
				)
			  }),
			catchError(this.global.chandleError)
		);

	}

	public  get_data_by_asset(qi?:data_query_input): Observable<data_query_response> {

		let url = this.global.hostUrl+ resturl+"query-events-by-asset";
		return this.get_data_common(url, qi);
	}

	public  get_data_by_entity(qi?:data_query_input): Observable<data_query_response> {

		let url = this.global.hostUrl+ resturl+"query-events";
		return this.get_data_common(url, qi);
	}

	public  get_rawdata(qi?:data_query_input): Observable<data_query_response> {

		let url = this.global.hostUrl+ resturl+"query-rawdata";
		return this.get_data_common(url, qi);
	}
}
