import { environment, tcapp } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient , HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders} from  '@angular/common/http';
import { BehaviorSubject, throwError, from  } from  'rxjs';
import { map, catchError, retry } from 'rxjs/operators';

import { Observable, Subject } from  'rxjs';
import { GlobalService } from './global.service';

class get_schema_input {
	qoptions : Map<string, qoptions>;
}

class qoptions {
	where : Map<string, string>;
}

export class config_schema {
    id		:string;
    name	:string;
    description	: string;
    entity_id	:string;
	cschema_type    :   string; // entity or asset
	cschema_type_id	:string;  // which asset controls this
	cschema_type_name	:string;  // which asset controls this
	cschema_json    : string;  //  Config schema json
	kv		: Map<string, string>;
	apply_schema_id		:string; //Schema to be used for applying config
	apply_schema_name	:string; //Schema to be used for applying config
	config_order		:number; //Order in which config should be pushed

	constructor(values: Object = {}) 
	{  
		Object.assign(this, values);  
	}

	public get_schema_type_id() {
		return (this.cschema_type_id);
	}

	public get_schema_type_name() {
		return (this.cschema_type_name);
	}

	public get_schema() {
		return (this.cschema_json);
	}
}

const resturl = "/tc/v1/config/";

@Injectable(
)

export class SchemaService {

    readystatus$: BehaviorSubject<boolean> = new BehaviorSubject(null);

    public schema_list:config_schema[];

	public ready():Observable<boolean> {
		return this.readystatus$.asObservable();
	}

	constructor(private  httpClient : HttpClient,
		private platform: Platform,
		private global: GlobalService, 
		private storage: Storage) {

		var getpromises = [];
		this.readystatus$.next(false);
	    this.schema_list = [];

		this.global.ready()
		.subscribe(ready => {
			if (!ready) {
				return
			}
			this.get_schemas().subscribe(resp => {
				  if (resp && resp.length) {
					this.readystatus$.next(true);
					console.log("Schema Service : Schemas loaded");
				  } else {
					console.log("Schema Service : No Schemas loaded");
				  }
			});
		});
	}

	public  get_schema_local(name?:string): config_schema {
		if (this.schema_list) {
			for(let item of this.schema_list ) {
				if (item.name == name) {
					return item;
				}
			}
		} else {
			return null;
		}
	}

	public  get_schema(name:string, id?:string): Observable<config_schema> {

		let url = this.global.hostUrl+ resturl+"get-schema";
		let input_rec = new (get_schema_input);

		//input_rec.id = id;
		let input_json = JSON.stringify({qoptions : {where :{name : name}}});

		let hdr = new HttpHeaders();

		console.log("Contacting", url, input_json);
		hdr = hdr.append('Accept', 'application/json');
		return this.httpClient
		.post<config_schema>(url, input_json, {headers:hdr})
		.pipe(
			retry(3),
			(map(response => {
				if (response ) {
					console.log(response); 
					return response;
				} else {
					return response; 
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public  get_schemas(): Observable<config_schema[]> {

		let url = this.global.hostUrl+ resturl+"list-schemas";

		let hdr = new HttpHeaders();

		console.log("Contacting", url);
	    this.schema_list = [];
		hdr = hdr.append('Accept', 'application/json');
		return this.httpClient
		.get<config_schema[]>(url, {headers:hdr})
		.pipe(
			retry(3),
			(map(response => {
				if (response ) {
				  console.log(response); 
					/*
				  for(let item of response ) {
					  let a = new config_schema(item);
					  this.schema_list.push(a);
				  }
					 */
				  this.schema_list = response;
				  return response;
				} else {
					return response; 
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}
}
