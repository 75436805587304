import { environment, tcapp } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient , HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders} from  '@angular/common/http';
import { BehaviorSubject, throwError, from  } from  'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
//import 'rxjs/add/observable/throw';

//import  'rxjs/add/operator/catch';
import { Observable, Subject } from  'rxjs';
import { AssetTypeService, asset_type_info} from '../services/asset_type.service';
import { GlobalService } from './global.service';


class delete_asset_input {
	name:string;
	qoptions : Map<string, qoptions>;
}

class list_assets_input {
	id:string;
	qoptions : Map<string, qoptions>;
}

class qoptions {
	where : Map<string, string>;
}

export interface assets{
    assets: Array<asset_info>;
}

export class asset_info {
    name	:string;
    parent_asset_id	:string;
    entity_id	:string;
    id		:string;
    asset_type	:string;
    subtype	:string;
    description	: string;
	kv		: Map<string, string>;
	//country	: string;
    mobile	:string;
    ipaddr	:string;
    serial_no : string;
    location 	:string;
    tags	: string;
    user_defined	: boolean;
    root_asset_flag	: boolean;
    configured	: boolean;
	collmap : string;

	public get_asset_id() {
		return (this.id);
	}

	public get_asset_type() {
		return (this.asset_type);
	}

	constructor(values: Object = {}) 
	{  
		Object.assign(this, values);  
	}
}

const resturl = "/tc/v1/assets/";

@Injectable(
)

export class AssetService {

    readystatus$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    public asset_list:asset_info[];

	public ready():Observable<boolean> {
		return this.readystatus$.asObservable();
	}

	public loginAs(token:string) {
	}

	public logoutAs() {
	}

	constructor(private  httpClient : HttpClient,
		private platform: Platform,
		private assetTypSvc : AssetTypeService,
		private global: GlobalService,
		private storage: Storage) {

		this.readystatus$.next(false);
	    this.asset_list = [];
		this.ngOnInit();
	}

	public ngOnInit() {
		var getpromises = [];

		this.global.ready()
		.subscribe(ready => {
			if (!ready) {
				return
			}
		  this.readystatus$.next(true);
		  console.log("Asset Service : Settings loaded");
		});
	}

	public delete_asset(asset:asset_info, token?:string): Observable<any> {

		var input_json ;
		let url = this.global.hostUrl+ resturl+"delete-asset";

		let hdr = new HttpHeaders();
		let  dp = new(delete_asset_input);

		//dp.id = asset.id;
		dp.name = asset.name;
		dp.qoptions = new Map<string, qoptions>();
		dp.qoptions['where'] = {entity_id : asset.entity_id, parent_asset_id:asset.parent_asset_id};

		// Set entity_id of the user
		input_json = JSON.stringify(dp);

		console.log("Contacting", url, input_json);
		hdr = hdr.append('Accept', 'application/json');
		hdr = hdr.append('observe', 'response');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.post(url, input_json, {headers:hdr, observe:'response'})
		.pipe(
			retry(3),
			(map(response => {
				if (response) {
					console.log(response); 
					return response;
				} else {
					return response
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public create_asset(asset:asset_info, modify_flag?:boolean): Observable<any> {

		var input_json ;
		var url:string;

		if (modify_flag) {
			url = this.global.hostUrl+ resturl+"modify-asset";
		} else {
			url = this.global.hostUrl+ resturl+"create-asset";
		}

		let hdr = new HttpHeaders();

		input_json = JSON.stringify(asset);

		console.log("Contacting", url, input_json);
		hdr = hdr.append('Accept', 'application/json');
		hdr = hdr.append('observe', 'response');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.post(url, input_json, {headers:hdr, observe:'response'})
		.pipe(
			retry(3),
			(map(response => {
				if (response) {
					console.log(response); 
					return response;
				} else {
					return response
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public  get_assets(id:string, which:string): Observable<asset_info[]> {

		var input_json ;
		var customsort = function (a, b) {
			let m1 = a.name.match(/(\d+)/g)[0];
			let m2 = b.name.match(/(\d+)/g)[0];
			return (Number(m1) - Number(m2));
			//			return (Number(a.match(/(\d+)/g)[0]) - Number(b.match(/(\d+)/g)[0]));
		}
		
	    this.asset_list = [];
		let input_rec = new(list_assets_input);
		let url = this.global.hostUrl+ resturl+"list-controlled-assets";

		if (which == "controlled") {
			input_json = JSON.stringify({qoptions : {where :{parent_asset_id : id}}});
		} else if (which == "owned") {
			input_rec.id=id;
			url = this.global.hostUrl+ resturl+"list-child-assets";
			input_json = JSON.stringify(input_rec);
		} else {
			input_rec.id=id;
			input_json = JSON.stringify(input_rec);
			url = this.global.hostUrl+ resturl+"list-assets";
		}

		let hdr = new HttpHeaders();

		console.log("Contacting", url, input_json);
		hdr = hdr.append('Accept', 'application/json');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.post<asset_info[]>(url, input_json, {headers:hdr})
		.pipe(
			retry(3),
			(map(response => {
				if (response && response.length > 0) {
					console.log(response); 
					for(let item of response ) {
						let a = new asset_info(item);
						this.asset_list.push(a);
					}
					this.asset_list.sort(customsort);
					response.sort(customsort);
					return response;
				} else {
					return response; 
				}
			})),
			catchError(this.global.chandleError)
		);
	}
}
