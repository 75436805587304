import { Component, Injector, OnInit } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuService } from './services/menu.service';
import { UserService } from './services/user.service';
import { GlobalService } from './services/global.service';
import { Menu_item } from './menu/menu.component';
import { Observable } from 'rxjs/Observable';
import { take } from 'rxjs/operators';
//import { SharedServicesModule } from './services/sharedservices.module';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
})

export class AppComponent implements OnInit {
	public appPages = [
		{
			title: 'Welcome. Please sign in',
			url: '/settings',
			icon: 'log-in'
		}
	];
	authState$: Observable<boolean>;

	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private usersvc: UserService,
		private navCtrl: NavController,
		// public menu: MenuService,
		private global: GlobalService) {

		this.global.ready()
			.pipe(take(1),)
			.subscribe(resp => {
				if (resp) {
					this.initializeApp();
				}
			});
	}

	username: string = "";

	initializeApp() {
		this.platform.ready().then(() => {
			this.statusBar.styleDefault();
			this.splashScreen.hide();
		});
	}

	ngOnInit() {
		// this.global.ready()
		// 	.subscribe(resp => {
		// 		if (!resp)
		// 			return;

		// 		console.log("App Name:", window.location.pathname);
		// 		var loginmenu: Menu_item =
		// 		{
		// 			check: '"!(authState$ | async)"',
		// 			title: 'Sign in',
		// 			url: '/settings',
		// 			icon: 'log-in',
		// 			action: 'login'
		// 		};

		// 		var user: Menu_item =
		// 		{
		// 			check: '"(authState$ | async)"',
		// 			title: '',
		// 			url: '/settings',
		// 			icon: 'contact',
		// 			action: 'account'
		// 		}

		// 		var logoutmenu: Menu_item =
		// 		{
		// 			check: '"(authState$ | async)"',
		// 			title: 'Sign out',
		// 			url: '/settings',
		// 			icon: 'log-out',
		// 			action: 'logout'
		// 		}

		// 		var menustatic: Menu_item[] = [
		// 			{
		// 				check: true,
		// 				title: 'Home',
		// 				url: '/home',
		// 				icon: 'Home',
		// 				action: ''
		// 			},
		// 			{
		// 				check: true,
		// 				title: 'Entities',
		// 				url: '/home/entity',
		// 				icon: 'folder-open',
		// 				action: ''
		// 			},
		// 			{
		// 				check: true,
		// 				title: 'Refresh App',
		// 				url: '',
		// 				icon: 'refresh-circle',
		// 				action: "reload"
		// 			}
		// 			/*
		// 			{
		// 			  check: true,
		// 			  title: 'My Account',
		// 			  url: '/settings',
		// 			  icon: 'contact',
		// 			  action: 'account'
		// 			}
		// 			 */
		// 		];

		// 		this.usersvc.ready().subscribe(resp => {
		// 			if (!resp)
		// 				return;
		// 			this.authState$ = this.usersvc.checkloginstatusObserver();
		// 			(this.authState$).subscribe((x) => {
		// 				var final_menu: any;
		// 				if (x == true) {
		// 					//final_menu = menustatic;
		// 					//final_menu.pop();
		// 					//final_menu.push(...logoutmenu);
		// 					final_menu = new Set(menustatic);
		// 					final_menu.add(logoutmenu);
		// 					this.menu.details.next(final_menu);
		// 					user.title = this.global.get_user_name();
		// 					final_menu = new Set([user]);
		// 					this.menu.title.next(final_menu);
		// 					/*
		// 					this.global.get_user_name().then(name => {
		// 						user.title = name;
		// 						final_menu = new Set([user]);
		// 						this.menu.title.next(final_menu);
		// 					});
		// 					this.global.get_user_name().then (
		// 						(value) => {
		// 							if (value) {
		// 								user.title = value;
		// 								final_menu = new Set([user]);
		// 								this.menu.title.next(final_menu);
		// 							}
		// 					});
		// 					 */
		// 				} else {
		// 					final_menu = new Set(menustatic);
		// 					final_menu.add(loginmenu);
		// 					this.menu.details.next(final_menu);
		// 				}
		// 			});
		// 		});
		// 	});
	}

	reload() {
		let url = window.location.origin + "/" + this.global.appName;
		window.location.replace(url);
		window.location.reload(true);
	}
}
