import { environment, tcapp } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient , HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders} from  '@angular/common/http';
import { BehaviorSubject, throwError, from  } from  'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { Observable, Subject } from  'rxjs';
import { GlobalService } from './global.service';


class delete_profile_input {
	name:string;
	qoptions : Map<string, qoptions>;
}

class list_profiles_input {
	qoptions : Map<string, qoptions>;
}

class qoptions {
	where : Map<string, string>;
}

export class config_profile {
    name		:string;
    entity_id	:string;
    id		:string;
	profile_type	:string;  // for asset or entity ?
	profile_type_id	:string;  // which entity/asset this corresponds to
	profile_type_name	:string;  // Name of entity/asset
	cschema_id : string;
	cschema_name : string;
	aschema_id : string;
	aschema_name : string;
	apply_to : any; //JSON with details where this profile has been applied
	published_to : any; //JSON with details where this profile has been applied
	config    : string;
	config_order    : number;
	sync_time    : number;
	apply_time    : number;
	kv		: Map<string, string>;

	constructor(values: Object = {}) 
	{  
		Object.assign(this, values);  
	}

	public get_profile_type() {
		return (this.profile_type);
	}

	public get_profile_type_id() {
		return (this.profile_type_id);
	}

	public get_profile_type_name() {
		return (this.profile_type_name);
	}

	public set_config(values: Object = {}) 
	{  
		Object.assign(this.config, values);  
	}

	public get_config() {
		return (this.config);
	}
}

const resturl = "/tc/v1/config/";

@Injectable(
)

export class ProfileService {

    readystatus$: BehaviorSubject<boolean> = new BehaviorSubject(null);
	public user_entity:string;
	public user_entity_type:number;

	public ready():Observable<boolean> {
		return this.readystatus$.asObservable();
	}

	constructor(private  httpClient : HttpClient,
		private platform: Platform,
		private global: GlobalService,
		private storage: Storage) {

		this.readystatus$.next(false);
		this.ngOnInit();
	}

	public ngOnInit() {
		var getpromises = [];

		this.global.ready()
		.subscribe(ready => {
			if (!ready) {
				return
			}

			this.readystatus$.next(true);
			console.log("profile Service : Settings loaded");
		});
	}

	public refresh() {
		this.global.ready()
		.subscribe(ready => {
			if (!ready) {
				return
			}
			this.readystatus$.next(true);
			console.log("profile Service : Settings refreshed");
		});
	}

	private mapToJson(map) {
		return JSON.stringify([...map]);
	}


	public delete_profile(profile:config_profile): Observable<any> {

		var input_json ;
		let url = this.global.hostUrl+ resturl+"delete-profile";

		let hdr = new HttpHeaders();
		let  dp = new(delete_profile_input);

		dp.name = profile.name;
		dp.qoptions = new Map<string, qoptions>();
		dp.qoptions['where'] = {entity_id : profile.entity_id,};

		// Set entity_id of the user
		input_json = JSON.stringify(dp);

		console.log("Contacting", url, input_json);
		hdr = hdr.append('Accept', 'application/json');
		hdr = hdr.append('observe', 'response');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.post(url, input_json, {headers:hdr, observe:'response'})
		.pipe(
			retry(3),
			(map(response => {
				if (response) {
					console.log(response); 
					return response;
				} else {
					return response
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public update_profile(profile:config_profile): Observable<any> {

		var input_json ;
		let url = this.global.hostUrl+ resturl+"modify-profile";

		let hdr = new HttpHeaders();

		//let date: Date = new Date();
		profile.apply_time = Math.floor(Date.now()/1000);

		// Set entity_id of the user
		input_json = JSON.stringify(profile);

		console.log("Contacting", url, input_json);
		hdr = hdr.append('Accept', 'application/json');
		hdr = hdr.append('observe', 'response');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.post(url, input_json, {headers:hdr, observe:'response'})
		.pipe(
			retry(3),
			(map(response => {
				if (response) {
					console.log(response); 
					return response;
				} else {
					return response
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public create_profile(profile:config_profile, modify_flag?:boolean): Observable<any> {

		var input_json ;
		var url:string;

		if (modify_flag) {
			url = this.global.hostUrl+ resturl+"modify-profile";
		} else {
			url = this.global.hostUrl+ resturl+"create-profile";
		}

		let hdr = new HttpHeaders();

		// Set entity_id of the user
		profile.entity_id = this.global.user_entity;
		if (profile.apply_to || profile.aschema_name == "")  {
			// Update apply_time only when modified
			profile.apply_time = Math.floor(Date.now()/1000);
		}
		input_json = JSON.stringify(profile);

		console.log("Contacting", url, input_json);
		hdr = hdr.append('Accept', 'application/json');
		hdr = hdr.append('observe', 'response');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.post(url, input_json, {headers:hdr, observe:'response'})
		.pipe(
			retry(3),
			(map(response => {
				if (response) {
					console.log(response); 
					return response;
				} else {
					return response
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public  get_profiles_by_asset(asset_id?:string): Observable<config_profile[]> {

		let input_rec = new (list_profiles_input);
		let url = environment.server_url+ resturl+"list-profiles";
		let hdr = new HttpHeaders();
		input_rec.qoptions = new Map<string, qoptions>();
		input_rec.qoptions['where'] = {apply_to : ' key ' + asset_id};
		input_rec.qoptions['contains_flag'] = true;
		var input_json = JSON.stringify(input_rec);

		console.log("Contacting", url);
		hdr = hdr.append('Accept', 'application/json');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.post<config_profile[]>(url,input_json, {headers:hdr})
		.pipe(
			retry(3),
			(map(response => {
				if (response && response.length > 0) {
					console.log(response); 
					return response;
				} else {
					return response; 
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public  get_profiles(id?:string): Observable<config_profile[]> {

		let input_rec = new (list_profiles_input);
		let url = this.global.hostUrl+ resturl+"list-profiles-by-entity";
		if (! id) {
			id = this.global.user_entity;
		}
		let hdr = new HttpHeaders();
		input_rec.qoptions = new Map<string, qoptions>();
		input_rec.qoptions['where'] = {entity_id : id};
		var input_json = JSON.stringify(input_rec);

		console.log("Contacting", url);
		hdr = hdr.append('Accept', 'application/json');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.post<config_profile[]>(url,input_json, {headers:hdr})
		.pipe(
			retry(3),
			(map(response => {
				if (response && response.length > 0) {
					console.log(response); 
					return response;
				} else {
					return response; 
				}
			})),
			catchError(this.global.chandleError)
		);
	}
}
