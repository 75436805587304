import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from "@angular/common";
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CacheModule } from 'ionic-cache-observable';
import { ReactiveFormsModule } from '@angular/forms';
import { APP_BASE_HREF } from '@angular/common';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { InterceptorService } from './interceptors/interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedServicesModule } from './services/sharedservices.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { DrawerMenuComponent } from './components/drawer-menu/drawer-menu.component';
import { GlobalService } from './services/global.service';


@NgModule({
  declarations: [AppComponent, DrawerMenuComponent], // ShowgaugeComponent],
  entryComponents: [],
  exports: [DrawerMenuComponent],
  imports: [
    BrowserModule,
    CacheModule,
    IonicModule.forRoot({ mode: "ios" }),
    IonicStorageModule.forRoot({
      name: '__mydb',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    SharedServicesModule.forRoot(),
    ReactiveFormsModule,
    NgxDatatableModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxEchartsModule.forRoot({ echarts })
  ],
  providers: [
    DatePipe,
    StatusBar,
    SplashScreen,
    GlobalService,
    //{provide: APP_BASE_HREF, useValue: '/rkauto'},
    { provide: APP_BASE_HREF, useValue: window['base-href'] },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
