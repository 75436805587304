import { environment, tcapp } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { Injectable, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient , HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders} from  '@angular/common/http';
import { BehaviorSubject, throwError, from  } from  'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { Observable, Subject } from  'rxjs';
import { AssetTypeService, asset_type_info} from '../services/asset_type.service';
import { GlobalService } from './global.service';
import { UserService } from './user.service';
import { take, takeUntil } from  'rxjs/operators';


const USER_ENTITY_TYPE = "USER_ENTITY_TYPE";
const USER_ENTITY_TYPE_STR = "USER_ENTITY_TYPE_STR";

class delete_entity_input {
	name:string;
	qoptions : Map<string, qoptions>;
}

class qoptions {
	where : Map<string, string>;
}

class list_entities_input {
	id : string;
	qoptions : Map<string, qoptions>;
}

export interface entities{
    entities: Array<entity_info>;
}

export class entity_info {
    name	:string;
    domain	:string;
    entity_id	:string;
    id		:string;
    entity_type	:number;
    serviced_by_entity_id	:string;
    address 	:string;
    address2	:string;
    city	:string;
    country	:string;
    email	:string;
    phone	:string;
    phones	:string[];
    state	: string;
    zip 	:string;
	kv		: Map<string, string>;
    tags	: string;

	public get_entity_id() {
		return (this.id);
	}

	public get_entity_type() {
		return (this.entity_type);
	}

	constructor(values: Object = {}) 
	{  
		Object.assign(this, values);  
	}
}

export class entity_inf {
    entity_i:entity_info;

	constructor(values: Object = {}) 
	{  
		this.entity_i = new (entity_info);
		Object.assign(this.entity_i, values);  
	}
	
	public get_entity_id() {
		return (this.entity_i.id);
	}
	public get_entity_type() {
		return (this.entity_i.entity_type);
	}
}

const resturl = "/tc/v1/entities/";

@Injectable(
)

export class EntityService implements OnInit {

	hostUrl:string = tcapp.server_url;
    readystatus$: BehaviorSubject<boolean> = new BehaviorSubject(null);
	public  user:string;
	public user_entity:string;
	public user_entity_type:number;
	public asset_types:asset_type_info[] = [];

	public asset_type_details(id:string):asset_type_info {
		if (this.asset_types) {
			for(let item of this.asset_types ) {
				if (item.id == id) {
					return item;
				}
			}
		} else {
			return null;
		}
	}


	public ready():Observable<boolean> {
		return this.readystatus$.asObservable();
	}

	public loginAs_func(token:string) {
	  /*
		this.get_stored_value(LOGINAS_TOKEN_KEY).then(resp => { 
			if (resp) 
				this.loginAs = true; 
			else 
				this.loginAs = false; 
			this.loginAs_token = resp;
		});
		this.loginAs = true; 
	   */
		//this.ngOnInit();
	}

	public logoutAs_func() {
	  /*
		this.loginAs_token = ""
		this.loginAs = false; 
	   */
		//this.ngOnInit();
	   this.onlogout();
	}

	constructor(private  httpClient : HttpClient,
		private platform: Platform,
		private assettypsvc : AssetTypeService,
		private usersvc: UserService,
		private global: GlobalService,
		private storage: Storage) {
		
		this.readystatus$.next(false);
		this.ngOnInit();
	}

	ngOnInit() {
		var getpromises = [];
		this.global.ready()
		  .subscribe(ready => {
			if (!ready) {
				return
			}
			let initstate = this.usersvc.checkloginstatusObserver();
			initstate.pipe(take(2),)
				.subscribe(resp => {
				if (resp) {
					this.init_on_login();
					console.log("Entity Service : Ready");
					/*
					if (this.asset_types.length > 0 ) {
						this.readystatus$.next(true);
						console.log("Entity Service : Settings loaded");
					}
					 */
				}
			});
		});
	}

	public onlogout() {
		this.readystatus$.next(false);
		this.asset_types = [];
	}

	public init_on_login() {
		this.get_self()
		  .subscribe(response => {
		  if (response && response.length) {
				var entity_type_str:string;
				this.user_entity_type = Number(response[0].entity_type);
				entity_type_str = this.get_entity_type_str(this.user_entity_type);
				this.global.user_entity_type = this.user_entity_type;
				this.storage.set(USER_ENTITY_TYPE, this.user_entity_type).then( res => {
					console.log("ENTITY type set to", res)
				});
				this.global.user_entity_type_string = entity_type_str;
				this.storage.set(USER_ENTITY_TYPE_STR, entity_type_str).then( res => {
					console.log("ENTITY type set to", res)
				});
				if (this.asset_types.length > 0 ) {
				   console.log("Entity Service : Settings loaded");
				   return;
				}
				this.assettypsvc.get_asset_types()
				  .subscribe(resp => {
					if (! resp || ! resp.length) {
						console.log("Entity Service : No asset types found");
					}  else {
						/*
						for(let item of resp )
						{   
							let a = new asset_type_info(item);
							this.asset_types.push(a);
						}
						 */
						this.asset_types = resp;
						console.log(this.asset_types); 
						this.readystatus$.next(true);
						console.log("Entity Service : Settings loaded");
					}
				  });
			}
		  });
	}

	private entity:entity_info;

	public get_entity_type_str(etype:number):string {

		var entity_type_str:string;
		switch(etype) {
			case 1 : entity_type_str = "SAAS PROVIDER"
					 break;
			case 2 : entity_type_str = "SERVICE PROVIDER"
					 break;
			case 3 : entity_type_str = "CUSTOMER"
					 break;
			case 4 : entity_type_str = "SITE"
					 break;
			default : entity_type_str = "OTHER"
					 break;
		}
		return(entity_type_str);
	}

	public get_entity_types(etype:number):string[] {
		let result = new Array();
		for(let i = etype + 1; i<=4 ;i++) {
			result.push(this.get_entity_type_str(i));
		}
		return(result);
	}

	public get_entity_child_type(etype:number):string[] {
		let result = new Array();
		result.push(this.get_entity_type_str(etype+1));
		return(result);
	}

	public  get_entity(entity_id:string): Observable<entity_info> {

		let url = this.hostUrl+ resturl+"get-entity";
		let hdr = new HttpHeaders();
		let input_rec = new (list_entities_input);
		input_rec.id = entity_id;
		var input_json = JSON.stringify(input_rec);
		console.log("Contacting", url);
		hdr = hdr.append('Accept', 'application/json');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.post<entity_info>(url, input_json, {headers:hdr})
		.pipe(
			//retry(3),
			(map(response => {
				if (response ) {
					console.log(response); 
					return response; 
				} else {
					return response; 
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public  get_self(): Observable<entity_info[]> {

		let url = this.hostUrl+ resturl+"get-entity";
		let hdr = new HttpHeaders();
		console.log("Contacting", url);
		hdr = hdr.append('Accept', 'application/json');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.get<entity_info[]>(url, {headers:hdr})
		.pipe(
			//retry(3),
			(map(response => {
				if (response && response.length > 0) {
					console.log(response); 
					return response; 
				} else {
					return response; 
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public create_entity(entity:entity_info, modify_flag?:boolean): Observable<any> {

		var input_json ;
		var url:string;

		if (modify_flag) {
			url = this.global.hostUrl+ resturl+"modify-entity";
		} else {
			url = this.global.hostUrl+ resturl+"create-entity";
		}

		let hdr = new HttpHeaders();

		// Set entity_id of the user
		//asset.entity_id = this.global.user_entity;
		input_json = JSON.stringify(entity);

		console.log("Contacting", url, input_json);
		hdr = hdr.append('Accept', 'application/json');
		hdr = hdr.append('observe', 'response');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.post(url, input_json, {headers:hdr, observe:'response'})
		.pipe(
			retry(3),
			(map(response => {
				if (response) {
					console.log(response); 
					return response;
				} else {
					return response
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public delete_entity(entity:entity_info, token?:string): Observable<any> {

		var input_json ;
		let url = this.global.hostUrl+ resturl+"delete-entity";

		let hdr = new HttpHeaders();
		let  dp = new(delete_entity_input);

		//dp.id = entity.id;
		dp.name = entity.name;
		dp.qoptions = new Map<string, qoptions>();
		//	dp.qoptions['where'] = {id:entity.id, entity_type:entity.entity_type, entity_id:entity.entity_id};
		dp.qoptions['where'] = {entity_id:entity.entity_id};

		// Set entity_id of the user
		input_json = JSON.stringify(dp);

		console.log("Contacting", url, input_json);
		hdr = hdr.append('Accept', 'application/json');
		hdr = hdr.append('observe', 'response');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.post(url, input_json, {headers:hdr, observe:'response'})
		.pipe(
			retry(3),
			(map(response => {
				if (response) {
					console.log(response); 
					return response;
				} else {
					return response
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public  get_entities(entity_id:string, which:string, token?:string): Observable<entity_info[]> {

		let url = this.global.hostUrl+ resturl+"list-controlled-entities";
		let hdr = new HttpHeaders();
		let input_rec = new (list_entities_input);
		var input_json; 

		if (which == "owned") {
			url = this.global.hostUrl+ resturl+"list-child-entities";
			input_rec.id = entity_id;
			input_json = JSON.stringify(input_rec);
			//input_rec.qoptions = new Map<string, qoptions>();
			//input_rec.qoptions['where'] = {owner_entity : entity_id};
		} else {
			//		input_rec.id = entity_id;
			input_json = JSON.stringify({qoptions : {where :{serviced_by_entity_id : entity_id}}});
		}

		console.log("Contacting", url);
		hdr = hdr.append('Accept', 'application/json');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.post<entity_info[]>(url, input_json, {headers:hdr})
		.pipe(
			retry(3),
			(map(response => {
				if (response && response.length > 0) {
					console.log(response); 
					return response; 
				} else {
					return response; 
					//		return Observable.of([]);
				}
			})),
			catchError(this.global.chandleError)
		);
	}

	public  get_child_entities(): Observable<entity_info[]> {

		if (! this.readystatus$.value) {
			console.log("Entity service not ready");
		}
		var url = this.global.hostUrl+ resturl;
		var hdr = new HttpHeaders();


		console.log("Contacting", url);
		hdr = hdr.append('Accept', 'application/json');
		if (this.global.loginAs_token) {
			hdr = hdr.append("Authorization", "Bearer " + this.global.loginAs_token) ;
		}
		return this.httpClient
		.get<entity_info[]>(url, {headers:hdr})
		.pipe(
			retry(3),
			(map(response => {console.log(response); return response; })),
			catchError(this.global.chandleError)
		);
	}

	public get_token_touse():string {
		if (this.global.loginAs) {
			return this.global.loginAs_token;
		} else {
			return this.global.token;
		}
	}


}
