import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { UserService } from '../services/user.service';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError, from } from 'rxjs';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

const TOKEN_KEY = "X-Auth-Token";

@Injectable({
	providedIn: 'root'
})

export class AuthGuard implements CanActivate {
	readystatus$: BehaviorSubject<boolean> = new BehaviorSubject(null);
	authState$: BehaviorSubject<boolean> = new BehaviorSubject(null);
	//authState$: Observable<boolean>;

	public ready(): Observable<boolean> {
		return this.readystatus$.asObservable();
	}

	constructor(
		private global: GlobalService,
		private usersvc: UserService,
		private navCtrl: NavController,
		private storage: Storage,
	) {

		this.authState$.next(false);
		this.readystatus$.next(false);
		this.global.ready().subscribe(resp => {
			this.usersvc.ready().subscribe(resp => {
				this.readystatus$.next(true);
			});
		});
	}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const currentUser = this.storage.get(TOKEN_KEY);
		if (currentUser) {
			// logged in so return true
			return true;
		}

		// not logged in so redirect to login page with the return url
		this.navCtrl.navigate(["/dashboard"], {
			queryParams: { returnUrl: state.url },
		});
		return false;
	}
}

	//     canActivate( next: ActivatedRouteSnapshot,
	// 			   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

	// 	   this.ready().subscribe(resp => {
	// 		    let authstate$ = this.usersvc.checkloginstatusObserver();
	// 			authstate$.pipe(take(2),)
	// 			  .subscribe(x => {
	// 					this.authState$.next(x);
	// 			  });
	// 	   });

	// 	   return this.authState$.asObservable();
	//    }

// }
