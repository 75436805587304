import { environment, tcapp } from '../../environments/environment';
import { NavController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Inject, Injectable, OnInit } from '@angular/core';
import { Observable, Subject, BehaviorSubject, throwError, from  } from  'rxjs';
import { ReplaySubject } from 'rxjs';
import { HttpClient , HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders} from  '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { APP_BASE_HREF } from '@angular/common';

const TOKEN_KEY = "X-Auth-Token";
const LOGINAS_TOKEN_KEY = "LoginAs-Token";
const REFRESH_TOKEN_KEY = "X-Refresh-Token";
const USER_NAME = "USER_NAME";
const USER = "USER";
const HOST_URL = "HOST_URL";
const ENTITY_ID = "ENTITY_ID";
const USER_ENTITY_TYPE = "USER_ENTITY_TYPE";
const USER_ENTITY_TYPE_STR = "USER_ENTITY_TYPE_STR";
const DEBUG = "DEBUG_FLAG";

@Injectable(
)

export class GlobalService implements OnInit{
	public debug:boolean;
	public hostUrl:string = environment.server_url;
	public token:string;
	public loginAs_token:string;
	public refresh_token:string;
	public loginAs:boolean;
	public user:any;
	public user_entity:string;
	public user_entity_type:number;
	public user_entity_type_string:string;
  //app_data:Storage;
    public app_data: ReplaySubject<any> = new ReplaySubject<any>(2);

	constructor(
		@Inject(APP_BASE_HREF) public appName:string,
		private httpClient : HttpClient,	
		private platform: Platform,
	    private updates:SwUpdate,
		private storage: Storage,
		private navCtrl: NavController,) {

		this.readystatus$.next(false);
		this.ngOnInit();
		var splitted = appName.split("/", 2);
		this.appName = splitted[1];
		console.log("Opening App: ", this.appName);
	}

    readystatus$: BehaviorSubject<boolean> = new BehaviorSubject(null);

	public ready():Observable<boolean> {
		return this.readystatus$.asObservable();
	}

	refresh()
	{
		this.ngOnInit();
	}

	ngOnInit() {
		var getpromises = [];
		this.platform.ready().then(() => {
			  //
			getpromises.push(this.get_stored_value(HOST_URL)
				.then (resp => { this.hostUrl = resp;}));
			//			this.hostUrl = this.get_stored_value(HOST_URL)
			getpromises.push(this.get_stored_value(DEBUG)
				.then (resp => { this.debug = JSON.parse(resp);}));
			getpromises.push(this.get_stored_value(TOKEN_KEY)
				.then (resp => { this.token = resp;}));
			getpromises.push(this.get_stored_value(REFRESH_TOKEN_KEY)
				.then (resp => { 
					if (resp) {
						this.refresh_token = resp;
					}
				}));
			getpromises.push(this.get_stored_value(LOGINAS_TOKEN_KEY)
				.then (resp => { 
					if (resp) 
						this.loginAs = true; 
					else 
						this.loginAs = false; 
					this.loginAs_token = resp;}));
			getpromises.push(this.get_stored_value(USER)
				.then (resp => { 
					if (resp) {
						this.user_entity = resp['entity_id'];
						this.user = resp;
					}
				}));
			getpromises.push(this.get_stored_value(USER_ENTITY_TYPE)
				.then (resp => { 
					if (resp) {
						this.user_entity_type = Number(resp);
					}
				}));
			getpromises.push(this.get_stored_value(USER_ENTITY_TYPE_STR)
				.then (resp => { 
					if (resp) {
						this.user_entity_type_string = resp;
					}
				}));
			Promise.all(getpromises).then((data) => {
				//this.readystatus$.next(true);
				console.log("Global Service : Settings loaded");
				this.readystatus$.next(true);
				//this.checkforswupdate();
			});
		});
	}

	public set_stored(key:string, value:any) {
		this.storage.set(key, value).then( res => {
			console.log(key + " set to", res)
		    this.refresh();
		});
	    
	}

	public get_stored(key:string):Promise<string> {
		return this.storage.get(key).then( res => {
			if (res) {
				console.log('Read from storage ', key, res);
				return(res);
			} 
		});
	}

	public async get_stored_value(key:string):Promise<string>{
		return await this.storage.get(key);
	}

	public convert_data_to_json(title:string, mesg:string):string {
	/* GH
	 * 0: ""rc":2,"d":"ih","et":850371,"p1":(842988,0,3),"m1":(842988,0,3),"v2":(842988,0,1,0,3),"v3":(843894,0,1,0,3),"ct":(0)" status: 404
	 */
		var re1 = /\(/gi
		var re2 = /\)/gi
		let str = mesg.replace(re1, "[");
		let str1 = '{' + str.replace(re2, "]") + '}';
	    let obj = JSON.parse(str1);
	    obj.title = title;
		return JSON.stringify(obj);
	}

	public  seturl (s){
		this.hostUrl = s.server;
		this.storage.set(HOST_URL, this.hostUrl).then( res => {
			console.log("URL set to", res)
		});
	}

	public customsort(a, b) {
		let m1 = a.match(/(\d+)/g)[0];
		let m2 = b.match(/(\d+)/g)[0];
		return (Number(m1) - Number(m2));
		//			return (Number(a.match(/(\d+)/g)[0]) - Number(b.match(/(\d+)/g)[0]));
	}

	public delete_user_auth(loginAS?:boolean) {
		this.loginAs = false;
		this.loginAs_token = null;
		this.token = null;
		this.refresh_token = null;
		this.storage.remove(LOGINAS_TOKEN_KEY).then( res => {
			console.log("Logged out(LoginAS)");
		})
		this.storage.remove(USER).then( res => {
			console.log("Deleted user");
		})
		this.storage.remove(TOKEN_KEY).then( res => {
			console.log("Deleted Access Token");
		})
		this.storage.remove(REFRESH_TOKEN_KEY).then( res => {
			console.log("Deleted Refresh Token ");
		})
	}

	public async get_user_role() {
		//return (this.user.role);
		return this.storage.get(USER).then( res => {
			if (res) {
				return (res['role']);
			}
		});
	}

	public get_user_name(){
		return (this.user.first_name + ' ' + this.user.last_name);
	  /*
		return this.storage.get(USER).then( res => {
			if (res) {
				return (res['first_name'] + ' ' + res['last_name']);
			}
		});
	   */
	}

    public checkforswupdate() {
		if (!this.updates.isEnabled) {
		  console.log("Service worker is not enabled");
		  return;
		}

		console.log("Check for updates");
		this.updates.checkForUpdate().then(update => {
			  console.log("Update is available");
			this.updates.activateUpdate().then(
				() => {
					document.location.reload();
				    console.log("Updating to latest app");
				});
		});

		/*
		this.updates.available.subscribe(event => {
			  console.log("Received Update available event");
			  this.updates.activateUpdate().then(
				() => {
					document.location.reload();
				    console.log("Updating to latest app");
			  });
		});
		*/
    }

    public reload() {
		this.checkforswupdate();
	    let url = window.location.origin + "/" + this.appName;
	    window.location.replace(url);
		//window.location.reload(true);
		//document.location.reload(true);
    }

	public loginAs_func(data:any) {
		this.user_entity = data.id;
		this.user_entity_type = data.entity_type;
		this.readystatus$.next(true);
		//this.refresh();
	}

	public chandleError(error: HttpErrorResponse) {
		var message:any;
		if (error && error.error instanceof Error) {
			console.error('An error occurred:', error.error.message);
			message = error.error.message;
		} else if (error && error.error instanceof ErrorEvent) {
		// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
			let message=error.statusText + error.error.message;
		} else {
		// The backend returned an unsuccessful response code.
		// The response body may contain clues as to what went wrong,
			if (error && typeof error.error  === "string") {
				console.error(
				  `Backend returned code ${error.status}, ` +
				  `body was: ${error.error}`);
			       if (error.statusText == "OK") {
					 message = error.error ;
				   } else {
					 message = error.statusText + ":"  + error.error ;
				   }
				   if (error.status == 401) {
						// this.navCtrl.navigateRoot('login');
					}
			} else {
				return throwError(error.error);
			}
		}
		// return an observable with a user-facing error message
		return throwError(message);
	}


	public load_file(filePath: string):Observable<any>  {  
		//filePath: 'assets/test.json'

		return this.httpClient.get(filePath);
		/*
		return this.httpClient
			.get(filePath)
			.map((data) => {
			   return(data);
			});
		 */
	}

	public getmoredata = function(data:any, cb_arg:any, count:number, period?:string):Observable<any> {

		var atype:string;
		var stime:string;

		let di = cb_arg.input;
		if (period != "") {

			switch (period) {
				case "lasthour" :
					atype = "5m";
					stime = this.global.get_UTC_last_hour();
					break;
				case "today" :
					atype = "1h";
					stime = this.global.get_UTC_start_of_day();
					break;
				case "last24h" :
					atype = "1h";
					stime = this.global.get_UTC_last_xdays(1);
					break;
				case "last7d" :
					atype = "1day";
					stime = this.global.get_UTC_last_xdays(7);
					break;
				case "last30d" :
					atype = "1week";
					stime = this.global.get_UTC_last_xdays(30);
					break;
				case "last3m" :
					atype = "1month";
					stime = this.global.get_UTC_last_xdays(30);
					break;
			}
		} else {
			atype = "1h";
			stime = this.global.get_UTC_last_xdays(1);
		}

		di.start_time = stime;
		di.end_time = this.global.get_UTC_time();

		if (data) {
			di.pcontext = data.pcontext;
		} else {
			di.pcontext = "";
		}
		di.foptions["limit"] = count;
		if (cb_arg.input.data_type == "events") {


			/*
			//di.start_time = this.get_UTC_start_of_day();
			di.start_time = this.get_UTC_start_of_week();
			di.data_type = data.data_type;
			di.entity_name = ename;
			di.resource = data.id;
			di.foptions = new Map();
			di.foptions["limit"] = 20;
			di.severity = ["info"];
			 */

			//return (cb_arg.service_func(di));
		} else {

			/*
			//di.start_time = this.get_UTC_start_of_day();
			di.start_time = this.get_UTC_start_of_week();
			di.data_type = data.data_type;
			di.entity_name = ename;
			di.resource = data.id;
			di.foptions = new Map();
			di.foptions["limit"] = 20;
			di.severity = ["info"];
			 */
			di.aggregation_type = "cnts" + atype;

		}
		return (cb_arg.service_func(di));
	}

	// public getmoredata1 = function(data:any, cb_arg:any, count:number, period?:string):Observable<any> {

	// 	var atype:string;
	// 	var stime:string;

	// 	let di = cb_arg.input;
	// 	if (period != "") {

	// 		switch (period) {
	// 			case "lasthour" :
	// 				atype = "5m";
	// 				stime = this.get_UTC_last_hour();
	// 				break;
	// 			case "today" :
	// 				atype = "1h";
	// 				stime = this.get_UTC_start_of_day();
	// 				break;
	// 			case "last24h" :
	// 				atype = "1h";
	// 				stime = this.get_UTC_last_xdays(1);
	// 				break;
	// 			case "last7d" :
	// 				atype = "1day";
	// 				stime = this.get_UTC_last_xdays(7);
	// 				break;
	// 			case "last30d" :
	// 				atype = "1week";
	// 				stime = this.get_UTC_last_xdays(30);
	// 				break;
	// 			case "last3m" :
	// 				atype = "1month";
	// 				stime = this.get_UTC_last_xdays(30);
	// 				break;
	// 		}
	// 	} else {
	// 		atype = "1h";
	// 		stime = this.get_UTC_last_xdays(1);
	// 	}

	// 	di.start_time = stime;
	// 	di.end_time = this.get_UTC_time();

	// 	if (data) {
	// 		di.pcontext = data.pcontext;
	// 	} else {
	// 		di.pcontext = "";
	// 	}
	// 	di.foptions["limit"] = count;
	// 	if (cb_arg.input.data_type == "events") {


	// 		/*
	// 		//di.start_time = this.get_UTC_start_of_day();
	// 		di.start_time = this.get_UTC_start_of_week();
	// 		di.data_type = data.data_type;
	// 		di.entity_name = ename;
	// 		di.resource = data.id;
	// 		di.foptions = new Map();
	// 		di.foptions["limit"] = 20;
	// 		di.severity = ["info"];
	// 		 */

	// 		//return (cb_arg.service_func(di));
	// 	} else {

	// 		/*
	// 		//di.start_time = this.get_UTC_start_of_day();
	// 		di.start_time = this.get_UTC_start_of_week();
	// 		di.data_type = data.data_type;
	// 		di.entity_name = ename;
	// 		di.resource = data.id;
	// 		di.foptions = new Map();
	// 		di.foptions["limit"] = 20;
	// 		di.severity = ["info"];
	// 		 */
	// 		di.aggregation_type = "cnts" + atype;

	// 	}
	// 	return (cb_arg.service_func(di));
	// }

    get_UTC_time() {
		var d = new Date();
	    return (d.toJSON());
	}

	get_UTC_last_xdays(num:number) {
		var dt = new Date(new Date().setDate(new Date().getDate() - num));
		/*
		let dt = d.getDate();
		let month = d.getMonth();
		if (dt <= num) {
			if (month == 2 || month == 4 || month == 6 || month == 9 || month == 11) {
				dt += 31 - num;
			} else if (month == 3) {
				dt += 28 - num;
			} else {
				dt += 30 - num;
			}
		} else {
			dt =- num;
		}

		d.setDate(dt);
		 */
	    return (dt.toJSON());
	}

	get_UTC_last_hour() {
		var d = new Date();
		let h = d.getHours() - 1;
		if (h < 0) {
			h = 0;
		}
		d.setHours(h,0,0,0);
	    return (d.toJSON());
	}

	get_UTC_start_of_day() {
		var d = new Date();
		d.setHours(0,0,0,0);
	    return (d.toJSON());
	}

	get_UTC_start_of_week() {
		var d = new Date();
		var day = d.getDay();
		d.setHours(0,0,0,0);
		if (day != 0) {
		   d.setHours(-24*day);
		} 
	    return (d.toJSON());
	}

	get_UTC_start_of_month() {
		var d = new Date();
		var date= d.getDate();
		d.setHours(0,0,0,0);
		d.setDate(1);
	    return (d.toJSON());
	}
}
